/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    strong: "strong",
    a: "a",
    blockquote: "blockquote",
    br: "br",
    hr: "hr",
    h1: "h1",
    em: "em",
    div: "div"
  }, _provideComponents(), props.components), {MDXGrid, Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  if (!MDXGrid) _missingMdxReference("MDXGrid", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Opportunity"), "\n", React.createElement(_components.p, null, "When used as a soil amendment, biochar can do a lot of good. Due to its porous nature, it absorbs and retains soil nutrients, it offers a prime habitat for soil microorganisms to thrive and breed, and it is also excellent for holding and retaining water and preventing runoff. On a broader scale, biochar contains stable carbon, and burying it can sequester carbon for hundreds to thousands of years, preventing it from being released into the atmosphere."), "\n", React.createElement(_components.p, null, "So when we had the opportunity to use biochar at scale on our land, we jumped at the chance. ", React.createElement(_components.strong, null, "In the fall of 2021, a group of individuals imagined a project that would measure the impact of using biochar in a tree planting project."), " There were several hopes and goals: to demonstrate how biochar and amendments can help trees grow in an environmentally stressed, degraded area; to sequester carbon into the soil, to hold water in the soil, to improve the soil for better growing conditions; and just in general, to do good things for the environment!"), "\n", React.createElement(_components.h2, null, "The Project"), "\n", React.createElement(_components.p, null, "To begin, we choose a ", React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/41%C2%B034'56.7%22N+122%C2%B042'11.0%22W/@41.582417,-122.703043,620m/data=!3m1!1e3!4m5!3m4!1s0x0:0xb4a548af68306055!8m2!3d41.582417!4d-122.703043"
  }, "one-acre sized planting location"), " that we considered stressed and degraded, primarily through over-grazing mismanagement and the combination of heat and drought. Foresters in this area do not believe many young tree saplings can survive the growing conditions here, which was an additional incentive to us to try."), "\n", React.createElement(MDXGrid, {
    cols: "cols2",
    images: [{
      alt: 'Dozer carving terrace',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[0]
    }, {
      alt: 'Terraced hillside',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[1]
    }]
  }), "\n", React.createElement(_components.p, null, "With the location identified, we terraced the hillside with the intent to prevent water erosion down the hill and instead capture any moisture and direct it towards the planting area."), "\n", React.createElement(_components.p, null, "We dug ditches and filled each planting site with biochar and compost mix. We soaked the char with water before covering with topsoil, dipped tree roots in a micorizal inoculant before planting, and covered the bare soil with mulch."), "\n", React.createElement(MDXGrid, {
    cols: "cols3",
    images: [{
      alt: 'Mini Excavators',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[2]
    }, {
      alt: 'Heron filling biochar bucket',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[3]
    }, {
      alt: 'Chuck pouring biochar bucket',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[4]
    }, {
      alt: 'Serge carrying buckets',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[6]
    }, {
      alt: 'Barb Planting',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[7]
    }, {
      alt: 'Grant Planting',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[8]
    }]
  }), "\n", React.createElement(_components.h2, null, "In Total"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "In total, we planted 199 Douglas Fir and 191 Ponderosa Pine in 1.2 acres.", React.createElement(_components.br), "\n", "There were four different planting area experiments.", React.createElement(_components.br), "\n", "We burried 12 cubic yards of biochar, 7 cubic yards of a compost/biochar mix, and topped with 8 cubic yards of mulch!"), "\n"), "\n", React.createElement("iframe", {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/NdnYFbjcjMs",
    title: "YouTube video player",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    allowfullscreen: true
  }), "\n", React.createElement("div", {
    id: "photo-album-button"
  }, React.createElement(Link, {
    to: "/photos/biocharPlantingProject/nov2021",
    className: "button"
  }, React.createElement(_components.p, null, "See More Nov 2021 Planting Day Photos"))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, null, "Updates 2022 -- How'd we do?"), "\n", React.createElement(_components.p, null, "https://irec.ucanr.edu/Weather_Physical_-_Biological_Data/?weather=station&station=225"), "\n", React.createElement(_components.p, null, "Well, for starters, the weather was brutal! It was ", React.createElement(_components.em, null, "HOT"), " and ", React.createElement(_components.em, null, "DRY"), "! It was difficult to watch the trees struggle with no additional help from us. But even with these extremely punishing conditions, we saw promising results."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "In the first year, it rained only 9.6\"!", React.createElement(_components.br), "\n", "Discounting December and January, it rained only 4.2 inches!!!", React.createElement(_components.br), "\n", "Summer temperatures were back-to-back-to-back reaching the 90's, 100's and above for MONTHS!"), "\n"), "\n", React.createElement(_components.h2, null, "Control Group"), "\n", React.createElement("div", {
    className: "mdx-grid-container"
  }, React.createElement("div", {
    className: "grid-image"
  }, React.createElement(GatsbyImage, {
    alt: "control group tree",
    image: getImage(props.data.mdx.frontmatter.embeddedImagesLocal[10])
  })), React.createElement("div", {
    className: "grid-description"
  }, React.createElement(_components.p, null, "We planted 34 trees in the Control Group in two rows just above the Biochar Terraces planting area. They were planted in the traditional manner, using the shovel to create a hole and a boot to firmly press the dirt around the young tree in the ground. No biochar or compost ammendments were added."), React.createElement(_components.p, null, "These trees struggled from the beginning and had a low survival rate.")), React.createElement("div", {
    className: "grid-table"
  }, React.createElement("table", null, React.createElement("tr", null, React.createElement("th", null, "Control Group Survival Rate"), "\n", React.createElement("th", null, "July 2022"), "\n", React.createElement("th", null, "October 2022")), React.createElement("tr", null, React.createElement("td", null, "Douglas Fir"), "\n", React.createElement("td", null, "20%"), "\n", React.createElement("td", null, "0%")), React.createElement("tr", null, React.createElement("td", null, "Ponderosa Pine"), "\n", React.createElement("td", null, "83%"), "\n", React.createElement("td", null, "33%"))))), "\n", React.createElement(_components.h2, null, "Side Hills"), "\n", React.createElement("div", {
    className: "mdx-grid-container"
  }, React.createElement("div", {
    className: "grid-image"
  }, React.createElement(GatsbyImage, {
    alt: "control group tree",
    image: getImage(props.data.mdx.frontmatter.embeddedImagesLocal[11])
  })), React.createElement("div", {
    className: "grid-description"
  }, React.createElement(_components.p, null, "We planted 60 Ponderosa Pine on the Side Hills adjacent to the Biochar Terraces planting area. These were planted in the traditional manner with shovel and boot, except that the tree roots were dipped in a micorizal innoculant just before planting."), React.createElement(_components.p, null, "Many of these trees did well; others died quickly depending on surrounding plant area conditions. Some were more exposed and others were nestled into the grass.")), React.createElement("div", {
    className: "grid-table"
  }, React.createElement("table", null, React.createElement("tr", null, React.createElement("th", null, "Side Hills Survival Rate"), "\n", React.createElement("th", null, "July 2022"), "\n", React.createElement("th", null, "October 2022")), React.createElement("tr", null, React.createElement("td", null, "Ponderosa Pine"), "\n", React.createElement("td", null, "87%"), "\n", React.createElement("td", null, "?"))))), "\n", React.createElement(_components.h2, null, "Upper Ledge"), "\n", React.createElement(MDXGrid, {
    cols: "cols3",
    images: [{
      alt: '',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[12]
    }, {
      alt: '',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[13]
    }]
  }), "\n", React.createElement(_components.p, null, "40 Trees were planted on the Upper Ledge. We used an auger to drill holes, filled these with approximately 3 gallons of a compost/biochar mix, watered the hole, dipped tree roots in micorizal inoculant dip, and planted the trees."), "\n", React.createElement(_components.p, null, "These trees were easier to plant; they also had far less biochar ammendment added to the ground. These trees always looked happy and solid, though stunted (when compared with trees from the biochar terrace planting group). They also had been discovered by critters, as there was evidence of broken tree tips from nibbling."), "\n", React.createElement("div", {
    className: "grid-table"
  }, React.createElement("table", null, React.createElement("tr", null, React.createElement("th", null, "Upper Ledge Survival Rate"), "\n", React.createElement("th", null, "July 2022"), "\n", React.createElement("th", null, "October 2022")), React.createElement("tr", null, React.createElement("td", null, "Douglas Fir"), "\n", React.createElement("td", null, "50%"), "\n", React.createElement("td", null, "0%")), React.createElement("tr", null, React.createElement("td", null, "Ponderosa Pine"), "\n", React.createElement("td", null, "100%"), "\n", React.createElement("td", null, "69%")))), "\n", React.createElement(_components.h2, null, "Biochar Terraces"), "\n", React.createElement(MDXGrid, {
    cols: "cols3",
    images: [{
      alt: '',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[14]
    }, {
      alt: '',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[15]
    }, {
      alt: '',
      childImageSharp: props.data.mdx.frontmatter.embeddedImagesLocal[16]
    }]
  }), "\n", React.createElement("div", {
    className: "grid-table"
  }, React.createElement("table", null, React.createElement("tr", null, React.createElement("th", null, "Biochar Terraces Survival Rate"), "\n", React.createElement("th", null, "July 2022"), "\n", React.createElement("th", null, "October 2022")), React.createElement("tr", null, React.createElement("td", null, "Douglas Fir"), "\n", React.createElement("td", null, "66%"), "\n", React.createElement("td", null, "45%")), React.createElement("tr", null, React.createElement("td", null, "Ponderosa Pine"), "\n", React.createElement("td", null, "100%"), "\n", React.createElement("td", null, "94%")))), "\n", React.createElement(_components.p, null, "256 trees were planted in the Biochar Terraces. We dug ditches and filled each planting site with approximately 8 gallons of (straight) biochar and 3 gallons of a compost/biochar mix. We soaked the char with water before covering with topsoil, we dipped tree roots in a micorizal inoculant before planting, and bare soil was covered with mulch."), "\n", React.createElement(_components.p, null, "And the results were fabulous."), "\n", React.createElement("iframe", {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/sEtg7evCHUA",
    title: "YouTube video player",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    allowfullscreen: true
  }), "\n", React.createElement("div", {
    id: "photo-album-button"
  }, React.createElement(Link, {
    to: "/photos/biocharPlantingProject/july2022",
    className: "button"
  }, React.createElement(_components.p, null, "See July 2022 Photos"))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Thank You!"), "\n", React.createElement(_components.p, null, "We are encouraged with these results -- not just with the tree survival rate in the Biochar Terrace planting area, but with the increased health, vigor, and biodiversity of plants that appeared beside them DESPITE the record-high temperatures and intense drought. We are confident that we have improved growing conditions and look forward to monitoring ongoing impacts."), "\n", React.createElement(_components.p, null, "We are extremely grateful for the inspiration, knowledge, encouragement, and participation of our partners -- Thank you!"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20.666666666666668%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAABIElEQVQY0yXL3y8CAQDA8f4if4TNk7c2b55YT1hjs7vk11+AJnXX1UU/XbFQhqHIuYtOdZ1lrAlt+jO+Vh4+D9+Hr6tmnmPbOm3H4P7umODuErIkjkiSgCr5UMMi0ZBAPOxDkVeIxzdQY2tEZB9KxIcs/Ru2azDoYX84dHrvdLs2SkTkYH+TaHSVVHSdRXmBqaSX2ayAW/Fw+1Dg+UnHsnRy2haBHe/oUSICUngZV7Nl0rRfeO04NJpVMmmJfC7LoZbis93Ck/fj1reZfgwylpvDejPpf/3y/dOnZhYxynESiT3UWABNC+FqNCrYTm3Eql+RSgY5PdEoFvOY5UvEtJ+J9DyTGS/jsRmuK3kMvYxp3FCpFqi3LyiVjjg7G0ryB5Gj7FqDg7GLAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Biochar Planting Partners\"\n        title=\"Biochar Planting Partners\"\n        src=\"/static/41bb788e6c0f129ae40305667c69fe3e/0a47e/BiocharPlantingProjectPartners.png\"\n        srcset=\"/static/41bb788e6c0f129ae40305667c69fe3e/8a4e8/BiocharPlantingProjectPartners.png 150w,\n/static/41bb788e6c0f129ae40305667c69fe3e/5a46d/BiocharPlantingProjectPartners.png 300w,\n/static/41bb788e6c0f129ae40305667c69fe3e/0a47e/BiocharPlantingProjectPartners.png 600w,\n/static/41bb788e6c0f129ae40305667c69fe3e/1cfc2/BiocharPlantingProjectPartners.png 900w,\n/static/41bb788e6c0f129ae40305667c69fe3e/c1b63/BiocharPlantingProjectPartners.png 1200w,\n/static/41bb788e6c0f129ae40305667c69fe3e/807a0/BiocharPlantingProjectPartners.png 1652w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
